<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <h3 class="p-2">
          ยอดฝาก / ยอดถอน
        </h3>
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="dateStart">วันเริ่มต้นยอดเล่น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="วันเริ่มต้นยอดเล่น"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="dateEnd">วันสิ้นสุดยอดเล่น</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="วันสิ้นสุดยอดเล่น"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="form-group"
            >
              <label for="username">รหัสสมาชิก</label>
              <input
                v-model="search_userID"
                type="text"
                class="form-control"
                placeholder="รหัสสมาชิก"
              >
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="form-group"
            >
              <label for="username">ยอดฝาก</label>
              <input
                v-model="search_DesAmt"
                type="text"
                class="form-control"
                placeholder="ยอดฝาก"
              >
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="form-group"
            >
              <label for="username">ยอดถอน</label>
              <input
                v-model="search_WidAmt"
                type="text"
                class="form-control"
                placeholder="ยอดถอน"
              >
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="btn btn-gradient-dark"
              @click="getWithdraw()"
            >
              <feather-icon icon="SearchIcon" /> ค้นหา
            </button>
          </div>
        </div>

      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            style="display: flex"
          >
            <b-button
              variant="gradient-primary"
              class="mr-1"
              @click="exportToExcel()"
            >
              โหลดไฟล์ Excel
            </b-button>
            <!-- <b-button

              variant="gradient-danger"
              @click="exportToPdf"
            >
              โหลดไฟล์ PDF
            </b-button> -->
          </div>
        </div>
      </b-card-body>
      <b-table
        ref="table"
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(userID)="data">
          {{ data.item.username }}
        </template>
        <template #cell(Deposit)="data">
          {{ Commas(data.item.amountall_deposit) }}
        </template>
        <template #cell(Withdrawal)="data">
          {{ Commas(data.item.amountall_withdraw) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getWithdraw()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getWithdraw()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

  </b-overlay>
</template>

<script>
// import moment from 'moment-timezone'
import {
  VBTooltip,
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20, 100, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      NumberItem: [
        { value: 1, text: 100 },
        { value: 7, text: 500 },
        { value: 2, text: 1000 },
        { value: 3, text: 1500 },
        { value: 4, text: 2000 },
      ],

      fields: [
        { key: 'index', label: 'No.' },
        { key: 'userID', label: 'รหัสสมาชิก' },
        { key: 'Deposit', label: 'ยอดฝาก' },
        { key: 'Withdrawal', label: 'ยอดถอน' },
      ],

      items: [
        // {
        //   index: 1, userID: '0999999999', Deposit: 1000, Withdrawal: 50000,
        // },
      ],
      dateStart: new Date(),
      dateEnd: new Date(),
      search_userID: null,
      search_DesAmt: null,
      search_WidAmt: null,
      show: false,
    }
  },
  mounted() {
    this.getWithdraw()
  },
  methods: {
    getWithdraw() {
      this.show = true
      const params = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        search_userID: this.search_userID,
        search_DesAmt: this.search_DesAmt,
        search_WidAmt: this.search_WidAmt,
        perPage: this.perPage,
        page: this.currentPage,
      }
      this.$http.get('/admin/SearchDepositWithdraw', { params })
        .then(response => {
          this.items = response.data.data
          this.totalRows = response.data.total
          this.show = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    exportToExcel() {
      this.show = true
      const params = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        search_userID: this.search_userID,
        search_DesAmt: this.search_DesAmt,
        search_WidAmt: this.search_WidAmt,
        perPage: this.perPage,
        page: this.currentPage,
      }
      this.$http.get('/admin/export/Deposit/Withdrawal', { params, responseType: 'arraybuffer' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'ยอดฝากถอน' // Specify the file name with .xlsx extension
          link.click()
          this.show = false
        }).catch(err => {
          console.error(err)
          this.show = false
        })
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
